<template>
  <div class="class-list">
    <!-- <GlobalInfoBar
      title="出库管理"
      content="导入并管理入库到繁星中转仓的订单"
    /> -->
    <!-- 文字修改 -->
    <GlobalInfoBar
      title="系统收货记录"
      content="统计从繁星中转仓入库时，系统自动确认收货的订单"
    />
    <!-- <Info/> -->
    <GlobalChunk icon="search" title="筛选查询">
      <div slot="filter">
        <From :receiveType="receiveType"/>
      </div>
      <Table  @accept="accept"/>
    </GlobalChunk>
  </div>
</template>

<script>
import From from "./Form";
import Table from "./Table";
export default {
  name: "class-list",
  components: {
    From,
    Table
  },
  data() {
    return {
      receiveType:'01'
    };
  },
  methods: {
    // 获取子组件table传来的参数
    accept(type){
      this.receiveType = type;
    }
  },
};
</script>

<style>
</style>