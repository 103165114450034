<template>
  <div class="GlobalFormDemo">
    <!-- 入库 -->
    <GlobalForm v-if="receiveType == '01'" :init-data="initData" :form-item-list="formItemList" :inline="true" :needBtnLoading="true"
      :labelWidth="90" round @handleConfirm="handleConfirm" confirmBtnName="查询">
      <el-form-item class="el_from" label="订单号" slot="orderNo" style="margin:0" label-width="75px">
        <el-input v-model="orderNo" placeholder="请输入订单号查询" clearable>
        </el-input>
      </el-form-item>
      <!-- <el-form-item
        class="el_from"
        label="外部订单号"
        slot="outOrderNo"
        style="margin:0"
      >
        <el-input v-model="outOrderNo" placeholder="请输入外部订单号查询" clearable>
        </el-input>
      </el-form-item> -->
      <el-form-item class="el_from" label="门店商家" slot="operationSelect" style="margin:0">
        <el-select v-model="companyId" placeholder="请选择门店商家" filterable clearable>
          <el-option v-for="item in merchantSelectList" :key="item.id" :label="item.name" :value="item.id"></el-option>
        </el-select>
      </el-form-item>
      <el-form-item class="el_from" label="回收商家" slot="operationSelect" style="margin:0">
        <el-select v-model="merchantId" placeholder="请选择回收商家" filterable clearable>
          <el-option v-for="item in shanpsSelectList" :key="item.merchantId" :label="item.merchantName"
            :value="item.merchantId"></el-option>
        </el-select>
      </el-form-item>
      <el-form-item class="el_from" label="成交时间" slot="turnoverSelect"
        style="margin: 0" label-width="85px">
        <el-date-picker v-model="turnTime" type="daterange" start-placeholder="开始日期" range-separator="~"
          end-placeholder="结束日期" clearable :default-time="['00:00:00', '23:59:59']">
        </el-date-picker>
      </el-form-item>
    </GlobalForm>
    <!-- 出库 -->
    <GlobalForm v-if="receiveType == '02'" :init-data="initData" :form-item-list="formItemList2" :inline="true" :needBtnLoading="true"
      :labelWidth="90" round @handleConfirm="handleConfirm" confirmBtnName="查询">
      <el-form-item class="el_from" label="订单号" slot="orderNo" style="margin:0">
        <el-input v-model="orderNo" placeholder="请输入订单号查询" clearable>
        </el-input>
      </el-form-item>
      <!-- <el-form-item
        class="el_from"
        label="外部订单号"
        slot="outOrderNo"
        style="margin:0"
      >
        <el-input v-model="outOrderNo" placeholder="请输入外部订单号查询" clearable>
        </el-input>
      </el-form-item> -->
      <el-form-item class="el_from" label="门店商家" slot="operationSelect" style="margin:0">
        <el-select v-model="companyId" placeholder="请选择门店商家" filterable clearable>
          <el-option v-for="item in merchantSelectList" :key="item.id" :label="item.name" :value="item.id"></el-option>
        </el-select>
      </el-form-item>
      <el-form-item class="el_from" label="回收商家" slot="operationSelect" style="margin:0">
        <el-select v-model="merchantId" placeholder="请选择回收商家" filterable clearable>
          <el-option v-for="item in shanpsSelectList" :key="item.merchantId" :label="item.merchantName"
            :value="item.merchantId"></el-option>
        </el-select>
      </el-form-item>
      <el-form-item class="el_from" label="成交时间" slot="turnoverSelect"
        style="margin: 0" label-width="75px">
        <el-date-picker v-model="turnTime" type="daterange" start-placeholder="开始日期" range-separator="~"
          end-placeholder="结束日期" clearable :default-time="['00:00:00', '23:59:59']">
        </el-date-picker>
      </el-form-item>
    </GlobalForm>
  </div>
</template>

<script>
import _api from "@/utils/request";
import moment from "moment";
import { encryptByDES } from "@/utils/3DES";
export default {
  name: "Form",
  props: {
    accountType: {
      require: true,
      type: String,
    },
    receiveType: {//入库：'01' /出库：'02'
      type: String,
      default: '01'
    },
  },
  data() {
    return {
      headers: {
        ACCESS_TOKEN: localStorage.getItem("token"),
        channel: "platformPc",
        TOKEN: "",
      },
      orderNo: "",
      outOrderNo: "",
      arrssy: {},
      pickerMinDate: null,
      pickerMaxDate: null,
      day31: 30 * 24 * 3600 * 1000,
      merchantId: "",
      initData: null,
      // 入库
      formItemList: [
        { slotName: "orderNo" },
        { slotName: "outOrderNo" },
        { slotName: "operationSelect" },
        // {
        //   key: "entryTime",
        //   type: "pickerOptions",
        //   labelName: "导入时间",
        //   placeholder: "请选择导入时间",
        //   valueFormat: "timestamp",
        //   clearable: true,
        //   pickerOptions: {
        //     onPick: ({ maxDate, minDate }) => {
        //       if (minDate && this.pickerMinDate) {
        //         this.pickerMinDate = null;
        //       } else if (minDate) {
        //         this.pickerMinDate = minDate.getTime();
        //       }
        //     },
        //     disabledDate: (time) => {
        //       if (this.pickerMinDate) {
        //         return (
        //           time.getTime() > this.pickerMinDate + this.day31 ||
        //           time.getTime() < this.pickerMinDate - this.day31
        //         );
        //       }
        //       return false;
        //     },
        //   },
        // },
        { slotName: "turnoverSelect" },
        // {
        //   key: "entryTime3",
        //   type: "pickerOptions",
        //   labelName: "成交时间",
        //   placeholder: "请选择成交时间",
        //   valueFormat: "timestamp",
        //   lableWidth: "100px",
        //   clearable: true,
        //   pickerOptions: {
        //     onPick: ({ maxDate, minDate }) => {
        //       if (minDate && this.pickerMinDate) {
        //         this.pickerMinDate = null;
        //       } else if (minDate) {
        //         this.pickerMinDate = minDate.getTime();
        //       }
        //     },
        //     disabledDate: (time) => {
        //       if (this.pickerMinDate) {
        //         return (
        //           time.getTime() > this.pickerMinDate + this.day31 ||
        //           time.getTime() < this.pickerMinDate - this.day31
        //         );
        //       }
        //       return false;
        //     },
        //   },
        // },
        {
          key: "entryTime4",
          type: "pickerOptions",
          labelName: "入库时间",
          placeholder: "请选择入库时间",
          valueFormat: "timestamp",
          clearable: true,
          pickerOptions: {
            onPick: ({ maxDate, minDate }) => {
              if (minDate && this.pickerMinDate) {
                this.pickerMinDate = null;
              } else if (minDate) {
                this.pickerMinDate = minDate.getTime();
              }
            },
            disabledDate: (time) => {
              if (this.pickerMinDate) {
                return (
                  time.getTime() > this.pickerMinDate + this.day31 ||
                  time.getTime() < this.pickerMinDate - this.day31
                );
              }
              return false;
            },
          },
        },
        {
          key: "entryTime2",
          type: "pickerOptions",
          labelName: "渠道收货时间",
          placeholder: "请选择渠道收货时间",
          valueFormat: "timestamp",
          lableWidth: "120px",
          clearable: true,
          pickerOptions: {
            onPick: ({ maxDate, minDate }) => {
              if (minDate && this.pickerMinDate) {
                this.pickerMinDate = null;
              } else if (minDate) {
                this.pickerMinDate = minDate.getTime();
              }
            },
            disabledDate: (time) => {
              if (this.pickerMinDate) {
                return (
                  time.getTime() > this.pickerMinDate + this.day31 ||
                  time.getTime() < this.pickerMinDate - this.day31
                );
              }
              return false;
            },
          },
        },
      ],
      // 出库
      formItemList2: [
        { slotName: "orderNo" },
        { slotName: "outOrderNo" },
        { slotName: "operationSelect" },
        {
          key: "entryTime",
          type: "pickerOptions",
          labelName: "导入时间",
          placeholder: "请选择导入时间",
          valueFormat: "timestamp",
          clearable: true,
          pickerOptions: {
            onPick: ({ maxDate, minDate }) => {
              if (minDate && this.pickerMinDate) {
                this.pickerMinDate = null;
              } else if (minDate) {
                this.pickerMinDate = minDate.getTime();
              }
            },
            disabledDate: (time) => {
              if (this.pickerMinDate) {
                return (
                  time.getTime() > this.pickerMinDate + this.day31 ||
                  time.getTime() < this.pickerMinDate - this.day31
                );
              }
              return false;
            },
          },
        },
        // {
        //   key: "entryTime4",
        //   type: "pickerOptions",
        //   labelName: "入库时间",
        //   placeholder: "请选择入库时间",
        //   valueFormat: "timestamp",
        //   clearable: true,
        //   pickerOptions: {
        //     onPick: ({ maxDate, minDate }) => {
        //       if (minDate && this.pickerMinDate) {
        //         this.pickerMinDate = null;
        //       } else if (minDate) {
        //         this.pickerMinDate = minDate.getTime();
        //       }
        //     },
        //     disabledDate: (time) => {
        //       if (this.pickerMinDate) {
        //         return (
        //           time.getTime() > this.pickerMinDate + this.day31 ||
        //           time.getTime() < this.pickerMinDate - this.day31
        //         );
        //       }
        //       return false;
        //     },
        //   },
        // },
        { slotName: "turnoverSelect" },
        // {
        //   key: "entryTime3",
        //   type: "pickerOptions",
        //   labelName: "成交时间",
        //   placeholder: "请选择成交时间",
        //   valueFormat: "timestamp",
        //   lableWidth: "100px",
        //   clearable: true,
        //   pickerOptions: {
        //     onPick: ({ maxDate, minDate }) => {
        //       if (minDate && this.pickerMinDate) {
        //         this.pickerMinDate = null;
        //       } else if (minDate) {
        //         this.pickerMinDate = minDate.getTime();
        //       }
        //     },
        //     disabledDate: (time) => {
        //       if (this.pickerMinDate) {
        //         return (
        //           time.getTime() > this.pickerMinDate + this.day31 ||
        //           time.getTime() < this.pickerMinDate - this.day31
        //         );
        //       }
        //       return false;
        //     },
        //   },
        // },
        {
          key: "entryTime2",
          type: "pickerOptions",
          labelName: "渠道收货时间",
          placeholder: "请选择渠道收货时间",
          valueFormat: "timestamp",
          lableWidth: "120px",
          clearable: true,
          pickerOptions: {
            onPick: ({ maxDate, minDate }) => {
              if (minDate && this.pickerMinDate) {
                this.pickerMinDate = null;
              } else if (minDate) {
                this.pickerMinDate = minDate.getTime();
              }
            },
            disabledDate: (time) => {
              if (this.pickerMinDate) {
                return (
                  time.getTime() > this.pickerMinDate + this.day31 ||
                  time.getTime() < this.pickerMinDate - this.day31
                );
              }
              return false;
            },
          },
        },
      ],
      merchantSelectList: [],
      shanpsSelectList: [],
      companyId: "",
      // 弹框
      importVisible: false,
      recordVisible: false,
      importOrderVisible: false,
      importOrderLoading: false,
      recordLoading: false,
      importId: "",
      recordPage: {
        pageNum: 1,
        pageSize: 10,
        total: 0,
      },
      importOrderPage: {
        pageNum: 1,
        pageSize: 10,
        total: 0,
      },
      recordColumns: [
        { slotName: "orderNum" },
        { slotName: "outStockDate" },
        { label: "操作人", prop: "optName" },
        { label: "操作时间", prop: "createTime" },
      ],
      recordData: [],
      importOrderData: [],
      importOrderColumns: [
        { label: "订单号", prop: "orderNo" },
        { slotName: "companyName" },
        { slotName: "merchantName" },
        { label: "操作时间", prop: "createTime" },
      ],
      turnTime: null, //成交时间
    };
  },
  created() {
    _api.listSimpleMerchantView({ pageNum: 1, pageSize: 999 }).then((res) => {
      if (res.code === 1) {
        this.shanpsSelectList = res.data;
      }
    });
    _api.getSelectList().then((res) => {
      if (res.code === 1) {
        this.merchantSelectList = res.data;
      }
    });
    let startTime = moment().format("YYYY-MM-DD 00:00:00"); // 当前时间往前推2天的时间
    let endTime = moment().format("YYYY-MM-DD 23:59:59");
    this.turnTime = [startTime, endTime];
    this.handleConfirm();
  },
  methods: {
    handleConfirm(data,cd) {
      let SeachParams = {};
      let startAckTime = "";
      let endAckTime = "";
      if (this.turnTime) {
        startAckTime = Number(moment(this.turnTime[0]).format("x"));
        endAckTime = Number(moment(this.turnTime[1]).format("x"));
      } else {
        startAckTime = "";
        endAckTime = "";
      }
      SeachParams.startAckTime = startAckTime
      SeachParams.endAckTime = endAckTime
      if (data) {
        let startTime = "";
        let endTime = "";
        if (!data.entryTime) {
          (startTime = ""), (endTime = "");
        } else {
          (startTime = moment(data.entryTime[0]).format("x")),
            (endTime = moment(data.entryTime[1]).format("x"));
        }
        let startTakeTime = "";
        let endTakeTime = "";
        if (!data.entryTime2) {
          (startTakeTime = ""), (endTakeTime = "");
        } else {
          (startTakeTime = moment(data.entryTime2[0]).format("x")),
            (endTakeTime = moment(data.entryTime2[1]).format("x"));
        }
        // if (!data.entryTime3) {
        //   (startAckTime = ""), (endAckTime = "");
        // } else {
        //   (startAckTime = moment(data.entryTime3[0]).format("x")),
        //     (endAckTime = moment(data.entryTime3[1]).format("x"));
        // }
        // 入库时间
        let startCreateTime = "";
        let endCreateTime = "";
        if (!data.entryTime4) {
          (startCreateTime = ""), (endCreateTime = "");
        } else {
          (startCreateTime = moment(data.entryTime4[0]).format("x")),
            (endCreateTime = moment(data.entryTime4[1]).format("x"));
        }
        if (this.receiveType == '01') {
          SeachParams = {
            startCreateTime: startCreateTime,
            endCreateTime: endCreateTime,
            merchantId: this.merchantId,
            companyId: this.companyId,
            orderNo: this.orderNo,
            outOrderNo: this.outOrderNo,
            // startTime: startTime,
            // endTime: endTime,
            startTakeTime: startTakeTime,
            endTakeTime: endTakeTime,
            startAckTime: startAckTime,
            endAckTime: endAckTime,
            pageNum: 1,
            pageSize: 10,
            disuseLoding: () => {
              if (typeof cd === 'function') {
                cd()
              }
            },
          };
        } else {
          SeachParams = {
            // startCreateTime:startCreateTime,
            // endCreateTime:endCreateTime,
            merchantId: this.merchantId,
            companyId: this.companyId,
            orderNo: this.orderNo,
            outOrderNo: this.outOrderNo,
            startTime: startTime,
            endTime: endTime,
            startTakeTime: startTakeTime,
            endTakeTime: endTakeTime,
            startAckTime: startAckTime,
            endAckTime: endAckTime,
            pageNum: 1,
            pageSize: 10,
            disuseLoding: () => {
              if (typeof cd === 'function') {
                cd()
              }
            },
          };
        }
      }
      this.arrssy = SeachParams;
      this.$store.commit("tagsView/SEACH_PARAMS", SeachParams);
    },
    download() {
      const link = document.createElement("a");
      link.href = "/static/导入出库订单模板.xlsx";
      link.setAttribute("download", "导入出库订单模板.xlsx");
      document.body.appendChild(link);
      link.click();
    },
    // 上传文件
    uploadFile(params) {
      const _file = params.file;
      // 通过 FormData 对象上传文件
      var formData = new FormData();
      formData.append("file", _file);
      // 发起请求
      _api.outStockImport(formData).then((res) => {
        if (res.code === 1) {
          this.$message({
            type: "success",
            message: "导入成功",
          });
          this.handleConfirm();
        } else {
          this.$message({
            type: "warning",
            message: res.msg,
          });
        }
      });
    },
    importSuccess() {
      this.$refs.upload.submit();
      this.importVisible = false;
      this.$refs.upload.clearFiles();
    },
    onUpload(e) {
      let TOKEN = encryptByDES(
        encryptByDES(
          new Date().getTime().toString(),
          "63e42c2444e94c1ebca21d30d2aa39a5"
        ),
        localStorage.getItem("token") || ""
      );
      this.headers.TOKEN = TOKEN;
    },
    recordSeeClose() {
      this.recordVisible = false;
    },
    importOrderSeeClose() {
      this.importId = "";
      this.importOrderVisible = false;
    },
    //导入出库记录表格分页
    recordChangeDialog(val) {
      if (val) {
        this.recordPage.pageNum = val;
      }
      let params = {
        pageNum: this.recordPage.pageNum,
        pageSize: this.recordPage.pageSize,
      };
      this.recordLoading = true;
      _api.outStockImportRecord(params).then((res) => {
        if (res.code === 1) {
          this.recordData = res.data.records;
          this.recordPage.pageNum = res.data.current;
          this.recordPage.total = res.data.total;
        }
        this.recordLoading = false;
      });
    },
    recordOrderClick(row) {
      console.log(row);
      this.importId = row.importId;
      this.importOrderVisible = true;
      this.importOrderChangeDialog(1);
    },
    //导入订单表格分页
    importOrderChangeDialog(val) {
      if (val) {
        this.importOrderPage.pageNum = val;
      }
      let params = {
        importId: this.importId,
        pageNum: this.importOrderPage.pageNum,
        pageSize: this.importOrderPage.pageSize,
      };
      this.importOrderLoading = true;
      _api.outStockImportRecordDetail(params).then((res) => {
        if (res.code === 1) {
          this.importOrderData = res.data.records;
          this.importOrderPage.pageNum = res.data.current;
          this.importOrderPage.total = res.data.total;
        }
        this.importOrderLoading = false;
      });
    },
  },
};
</script>

<style scoped lang="scss" ref="stylesheet/scss">
.GlobalFormDemo {
  .import-business-dialog {
    .step {
      .step-title {
        color: #0981ff;
        margin-bottom: 10px;
        padding-left: 6px;
        border-left: 3px solid;
        border-radius: 1.5px;
      }

      .step-content,
      .step2-content {
        margin-bottom: 26px;
      }

      .step2-content>div {
        display: flex;
      }

      .step2-content {
        .upload-demo {
          display: flex;
          justify-content: start;

          .el-upload-list {
            width: 200px;
          }
        }
      }

      .tip {
        margin-bottom: 12px;

        .tip-title {
          color: #666666;
          margin-right: 12px;
        }

        .tip-content {
          color: #ff687b;
          font-size: 12px;
        }
      }
    }
  }

  .cance-tip {
    color: #ff8080;
    font-size: 14px;
    margin-bottom: 20px;
  }
}
</style>