<template>
  <div class="GlobalTable">
    <div class="top_swith">
      <div class="button_top">
        <div class="radiobtn">
          <div class="faultBtn">
            <div v-bind:class="receiveType === '01' ? 'left' : 'right'" @click="BtnClick('01')">
              入库收货记录
            </div>
            <div v-bind:class="receiveType === '02' ? 'left' : 'right'" @click="BtnClick('02')">
              导入收货记录
            </div>
          </div>
        </div>
      </div>
      <!-- 右边按钮 -->
      <div slot="AddSlot">
        <el-button v-if="receiveType == '02'" type="primary" size="mini"
          @click.native="importVisible = true">导入已出库订单</el-button>
        <el-button v-if="receiveType == '02'" type="warning" size="mini" @click.native="
          () => {
            recordVisible = true;
            recordChangeDialog(1);
          }
        ">查看导入记录</el-button>
        <el-button icon="el-icon-download" type="success" size="mini" :loading="exportLoading" @click.native="outStockExcel">导出Excel</el-button>
      </div>
    </div>
    <!-- 入库收货记录 -->
    <GlobalTable v-if="receiveType == '01'" ref="GlobalTable" v-loading="loading" :columns="tableColumns01"
      :data="this.seachDataList" :currentPage="page.pageNum" :total="page.total"
      @handleCurrentChange="handleCurrentChange">
      <el-table-column label="回收订单号" slot="orderNo" align="center">
        <template slot-scope="{ row }">
          <span v-if="row.orderNo" style="color: #0981ff; cursor: pointer;text-decoration: underline"
            @click="goDetails(row)">
            {{ row.orderNo }}</span>
          <span v-else>--</span>
        </template>
      </el-table-column>

      <el-table-column label="门店商家" slot="companyName" align="center">
        <template slot-scope="{ row }">
          <span> {{ row.companyName || "--" }}</span>
        </template>
      </el-table-column>

      <el-table-column label="成交回收商" slot="merchantName" align="center">
        <template slot-scope="{ row }">
          <span> {{ row.merchantName || "--" }}</span>
        </template>
      </el-table-column>
      <el-table-column label="回收商交易额" slot="quotePrice" align="center">
        <template slot-scope="{ row }">
          <span v-if="row.quotePrice">￥{{ row.quotePrice }}</span>
          <span v-else>--</span>
        </template>
      </el-table-column>
      <el-table-column label="是否为外部订单" slot="isOneline" width="110" align="center">
        <template slot-scope="{ row }">
          <span> {{ row.isOneline ? "是" : "否" }}</span>
        </template>
      </el-table-column>
      <!-- <el-table-column label="外部订单号" slot="outOrderNo" align="center">
        <template slot-scope="{ row }">
          <span> {{ row.outOrderNo || "--" }}</span>
        </template>
      </el-table-column> -->
      <el-table-column label="外部确认收货" slot="takeState" width="100" align="center">
        <template slot-scope="{ row }">
          <span> {{ row.takeState == '01' ? "已收货" : "未收货" }}</span>
        </template>
      </el-table-column>

      <el-table-column label="订单状态" slot="state" width="80" align="center">
        <template slot-scope="{ row }">
          <span v-if="row.state === '00'">待确认</span>
          <span v-else-if="row.state === '01'">已确认</span>
          <span v-else-if="row.state === '02'">待支付</span>
          <span v-else-if="row.state === '03'">已绑码</span>
          <span v-else-if="row.state === '04'">已收货</span>
          <span v-else-if="row.state === '05'">降价收货</span>
          <span v-else-if="row.state === '10'">已取消</span>
          <span v-else-if="row.state === '20'">已作废</span>
          <span v-else-if="row.state === '30'">已退回</span>
          <span v-else>--</span>
        </template>
      </el-table-column>

      <el-table-column label="成交时间" slot="ackTime" align="center">
        <template slot-scope="{ row }">
          <span> {{ row.ackTime || '--' }}</span>
        </template>
      </el-table-column>

      <el-table-column label="入库时间" slot="importTime" align="center">
        <template slot-scope="{ row }">
          <span> {{ row.importTime || '--' }}</span>
        </template>
      </el-table-column>

      <el-table-column label="渠道自动收货时间" slot="takeDeliveryTime" align="center">
        <template slot-scope="{ row }">
          <span :style="row.takeState == '01' ? 'color:blue' : 'color:red'"> {{ row.takeDeliveryTime || '--' }}</span>
        </template>
      </el-table-column>

      <el-table-column label="回收商收货时间" slot="takeDeliveryTimePlatform" align="center">
        <template slot-scope="{ row }">
          <span> {{ row.takeDeliveryTimePlatform || '--' }}</span>
        </template>
      </el-table-column>
    </GlobalTable>
    <!-- 导入/出库收货记录 -->
    <GlobalTable v-if="receiveType == '02'" ref="GlobalTable" v-loading="loading" :columns="tableColumns02"
      :data="this.seachDataList" :currentPage="page.pageNum" :total="page.total"
      @handleCurrentChange="handleCurrentChange2">
      <el-table-column label="回收订单号" slot="orderNo" align="center">
        <template slot-scope="{ row }">
          <span v-if="row.orderNo" style="color: #0981ff; cursor: pointer;text-decoration: underline"
            @click="goDetails(row)">
            {{ row.orderNo }}</span>
          <span v-else>--</span>
        </template>
      </el-table-column>

      <el-table-column label="门店商家" slot="companyName" align="center">
        <template slot-scope="{ row }">
          <span> {{ row.companyName || "--" }}</span>
        </template>
      </el-table-column>

      <el-table-column label="成交回收商" slot="merchantName" align="center">
        <template slot-scope="{ row }">
          <span> {{ row.merchantName || "--" }}</span>
        </template>
      </el-table-column>
      <el-table-column label="回收商交易额" slot="quotePrice" align="center">
        <template slot-scope="{ row }">
          <span v-if="row.quotePrice">￥{{ row.quotePrice }}</span>
          <span v-else>--</span>
        </template>
      </el-table-column>
      <el-table-column label="是否为外部订单" slot="isOneline" width="110" align="center">
        <template slot-scope="{ row }">
          <span> {{ row.isOneline ? "是" : "否" }}</span>
        </template>
      </el-table-column>
      <!-- <el-table-column label="外部订单号" slot="outOrderNo" align="center">
        <template slot-scope="{ row }">
          <span> {{ row.outOrderNo || "--" }}</span>
        </template>
      </el-table-column> -->
      <el-table-column label="外部确认收货" slot="takeState" width="100" align="center">
        <template slot-scope="{ row }">
          <span> {{ row.takeState == '01' ? "已收货" : "未收货" }}</span>
        </template>
      </el-table-column>

      <el-table-column label="订单状态" slot="state" width="80" align="center">
        <template slot-scope="{ row }">
          <span v-if="row.state === '00'">待确认</span>
          <span v-else-if="row.state === '01'">已确认</span>
          <span v-else-if="row.state === '02'">待支付</span>
          <span v-else-if="row.state === '03'">已绑码</span>
          <span v-else-if="row.state === '04'">已收货</span>
          <span v-else-if="row.state === '05'">降价收货</span>
          <span v-else-if="row.state === '10'">已取消</span>
          <span v-else-if="row.state === '20'">已作废</span>
          <span v-else-if="row.state === '30'">已退回</span>
          <span v-else>--</span>
        </template>
      </el-table-column>

      <el-table-column label="成交时间" slot="ackTime" align="center">
        <template slot-scope="{ row }">
          <span> {{ row.ackTime || '--' }}</span>
        </template>
      </el-table-column>

      <el-table-column label="入库时间" slot="importTime" align="center">
        <template slot-scope="{ row }">
          <span> {{ row.importTime || '--' }}</span>
        </template>
      </el-table-column>

      <el-table-column label="渠道自动收货时间" slot="takeDeliveryTime" align="center">
        <template slot-scope="{ row }">
          <span :style="row.takeState == '01' ? 'color:blue' : 'color:red'"> {{ row.takeDeliveryTime || '--' }}</span>
        </template>
      </el-table-column>

      <el-table-column label="回收商收货时间" slot="takeDeliveryTimePlatform" align="center">
        <template slot-scope="{ row }">
          <span> {{ row.takeDeliveryTimePlatform || '--' }}</span>
        </template>
      </el-table-column>
    </GlobalTable>

    <!-- 弹框 -->
    <el-dialog title="导入出库订单" :visible.sync="importVisible" :close-on-click-modal="false" class="import-business-dialog"
      width="500px">
      <div class="cance-tip">
        <div>
          说明：请下载数据模板将博远出库单的手工单号复制到列表导入
        </div>
      </div>
      <div class="step">
        <div class="step-title">
          <span>Step1</span>
        </div>
        <div class="step-content">
          <span style="margin-right: 12px">表格下载</span>
          <el-link type="primary" @click="download">点击下载模板表格</el-link>
        </div>
        <div class="step-title">
          <span>Step2</span>
        </div>
        <div class="tip">
          <span class="tip-title">上传表格</span>
          <span class="tip-content">请上传.xls或.xlsx格式的excel文件，大小10M内</span>
        </div>
        <div class="step2-content">
          <el-upload ref="upload" :limit="1" :headers="headers" :auto-upload="false" :http-request="uploadFile"
            :before-upload="onUpload" accept=".xls,.xlsx" action="customize">
            <el-button slot="trigger" size="small" type="primary">选取文件</el-button>
          </el-upload>
        </div>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click.native="importVisible = false">取消</el-button>
        <el-button type="primary" @click.native="importSuccess">确定</el-button>
      </span>
    </el-dialog>
    <DialogTbale :loading="recordLoading" :show="recordVisible" title="导入出库记录" :columns="recordColumns" :data="recordData"
      :currentPage="recordPage.pageNum" :total="recordPage.total" :pageSize="recordPage.pageSize" :width="700"
      :isIndex="true" @onClose="recordSeeClose" @handleCurrentChange="recordChangeDialog">
      <el-table-column label="导入订单" slot="orderNum" align="center">
        <template slot-scope="{ row }">
          <div style="color: #0981ff; cursor: pointer;text-decoration: underline" @click="recordOrderClick(row)">
            {{ row.orderNum }}
          </div>
        </template>
      </el-table-column>
      <el-table-column label="设置出库时间" slot="outStockDate" align="center">
        <template slot-scope="{ row }">
          <span>操作{{ row.outStockDate }}分钟后</span>
        </template>
      </el-table-column>
    </DialogTbale>
    <DialogTbale :loading="importOrderLoading" :show="importOrderVisible" title="查看导入订单" :columns="importOrderColumns"
      :data="importOrderData" :currentPage="importOrderPage.pageNum" :total="importOrderPage.total"
      :pageSize="importOrderPage.pageSize" :width="750" :isIndex="true" @onClose="importOrderSeeClose"
      @handleCurrentChange="importOrderChangeDialog">
      <el-table-column label="门店商家" slot="companyName" align="center">
        <template slot-scope="{ row }">
          <span>{{ row.companyName || "--" }}</span>
        </template>
      </el-table-column>
      <el-table-column label="成交回收商" slot="merchantName" align="center">
        <template slot-scope="{ row }">
          <span>{{ row.merchantName || "--" }}</span>
        </template>
      </el-table-column>
    </DialogTbale>
  </div>
</template>

<script>
import _api from "@/utils/request";
import moment from "moment";
import { encryptByDES } from "@/utils/3DES";
export default {
  name: "Table",
  // props: {
  //   receiveType: {
  //     type: String,
  //     default: '01'
  //   },
  // },
  data() {
    return {
      headers: {
        ACCESS_TOKEN: localStorage.getItem("token"),
        channel: "platformPc",
        TOKEN: "",
      },
      // 弹框
      importVisible: false,
      recordVisible: false,
      importOrderVisible: false,
      importOrderLoading: false,
      recordLoading: false,
      importId: "",
      recordPage: {
        pageNum: 1,
        pageSize: 10,
        total: 0,
      },
      importOrderPage: {
        pageNum: 1,
        pageSize: 10,
        total: 0,
      },
      recordColumns: [
        { slotName: "orderNum" },
        { slotName: "outStockDate" },
        { label: "操作人", prop: "optName" },
        { label: "操作时间", prop: "createTime" },
      ],
      recordData: [],
      importOrderData: [],
      importOrderColumns: [
        { label: "订单号", prop: "orderNo" },
        { slotName: "companyName" },
        { slotName: "merchantName" },
        { label: "操作时间", prop: "createTime" },
      ],
      // *****
      receiveType: '01',
      handleShow: false,
      loading: false,
      seachDataList: [],//返回的数据
      auditStatus: "00",
      auditStatusRadio: "",
      butLoading: false,
      enableButLoading: false,
      page: {
        pageNum: 1,
        pageSize: 10,
      },
      tableColumns01: [
        { slotName: "orderNo" },
        { slotName: "companyName" },
        { slotName: "merchantName" },
        { slotName: "quotePrice" },
        { slotName: "isOneline" },
        // { slotName: "outOrderNo" },
        { slotName: "takeState" },
        { slotName: "state" },
        { slotName: "ackTime" },
        { slotName: "importTime" },
        { slotName: "takeDeliveryTime" },
        { slotName: "takeDeliveryTimePlatform" },
      ],
      tableColumns02: [
        { slotName: "orderNo" },
        { slotName: "companyName" },
        { slotName: "merchantName" },
        { slotName: "quotePrice" },
        { slotName: "isOneline" },
        // { slotName: "outOrderNo" },
        { slotName: "takeState" },
        { slotName: "state" },
        { slotName: "ackTime" },
        { slotName: "importTime" },
        { slotName: "takeDeliveryTime" },
        { slotName: "takeDeliveryTimePlatform" },
      ],
      exportLoading: false
      // 弹框
    };
  },
  created() {
    this.handleCurrentChange();
  },
  computed: {
    SeachParams() {
      return this.$store.state.tagsView.SeachParams;
    },
  },
  watch: {
    SeachParams(newVal) {
      console.log(newVal);
      this.page.pageNum = 1;
      if (this.receiveType == '01') {
        this.handleCurrentChange();
      } else {
        this.handleCurrentChange2();
      }
    },
  },
  methods: {
    download() {
      const link = document.createElement("a");
      link.href = "/static/导入出库订单模板.xlsx";
      link.setAttribute("download", "导入出库订单模板.xlsx");
      document.body.appendChild(link);
      link.click();
    },
    // 上传文件
    uploadFile(params) {
      const _file = params.file;
      // 通过 FormData 对象上传文件
      var formData = new FormData();
      formData.append("file", _file);
      // 发起请求
      _api.outStockImport(formData).then((res) => {
        if (res.code === 1) {
          this.$message({
            type: "success",
            message: "导入成功",
          });
          this.handleConfirm();
        } else {
          this.$message({
            type: "warning",
            message: res.msg,
          });
        }
      });
    },
    importSuccess() {
      this.$refs.upload.submit();
      this.importVisible = false;
      this.$refs.upload.clearFiles();
    },
    onUpload(e) {
      let TOKEN = encryptByDES(
        encryptByDES(
          new Date().getTime().toString(),
          "63e42c2444e94c1ebca21d30d2aa39a5"
        ),
        localStorage.getItem("token") || ""
      );
      this.headers.TOKEN = TOKEN;
    },
    recordSeeClose() {
      this.recordVisible = false;
    },
    importOrderSeeClose() {
      this.importId = "";
      this.importOrderVisible = false;
    },
    //导入出库记录表格分页
    recordChangeDialog(val) {
      if (val) {
        this.recordPage.pageNum = val;
      }
      let params = {
        pageNum: this.recordPage.pageNum,
        pageSize: this.recordPage.pageSize,
      };
      this.recordLoading = true;
      _api.outStockImportRecord(params).then((res) => {
        if (res.code === 1) {
          this.recordData = res.data.records;
          this.recordPage.pageNum = res.data.current;
          this.recordPage.total = res.data.total;
        }
        this.recordLoading = false;
      });
    },
    recordOrderClick(row) {
      console.log(row);
      this.importId = row.importId;
      this.importOrderVisible = true;
      this.importOrderChangeDialog(1);
    },
    //导入订单表格分页
    importOrderChangeDialog(val) {
      if (val) {
        this.importOrderPage.pageNum = val;
      }
      let params = {
        importId: this.importId,
        pageNum: this.importOrderPage.pageNum,
        pageSize: this.importOrderPage.pageSize,
      };
      this.importOrderLoading = true;
      _api.outStockImportRecordDetail(params).then((res) => {
        if (res.code === 1) {
          this.importOrderData = res.data.records;
          this.importOrderPage.pageNum = res.data.current;
          this.importOrderPage.total = res.data.total;
        }
        this.importOrderLoading = false;
      });
    },
    outStockExcel() {
      if (this.receiveType == '01') {//导入出库订单
        console.log('010101');
        this.exportLoading = true
        const baseRequest1 = {
          merchantId: this.SeachParams.merchantId,
          companyId: this.SeachParams.companyId,
          orderNo: this.SeachParams.orderNo,
          // outOrderNo: this.SeachParams.outOrderNo,
          // startTime: this.SeachParams.startTime,
          // endTime: this.SeachParams.endTime,
          startTakeTime: this.SeachParams.startTakeTime,
          endTakeTime: this.SeachParams.endTakeTime,
          startAckTime: this.SeachParams.startAckTime,
          endAckTime: this.SeachParams.endAckTime,
          endCreateTime: this.SeachParams.endCreateTime,
          startCreateTime: this.SeachParams.startCreateTime,
          pageNum: this.page.pageNum,
          pageSize: this.page.pageSize,
          isIn: true,//true:入库 false：出库
        };
        _api.outStockExcelInStorage({ isIn: true, ...baseRequest1}).then((res) => {
          const content = res;
          const blob = new Blob([content]);

          let reader = new FileReader(); // 创建读取文件对象
          reader.addEventListener("loadend", () => {
            //
            try {
              let data = JSON.parse(reader.result);
              if (data.code === 0) {
                this.$message.error(data.msg || "导出失败");
                this.commandBtnLoading = false
              }
            } catch (err) {
              let start = "";
              let end = ""
              if (this.SeachParams.startCreateTime && this.SeachParams.endCreateTime) {
                start = moment(Number(this.SeachParams.startCreateTime)).format("YYYY.MM.DD")
                end = moment(Number(this.SeachParams.endCreateTime)).format("YYYY.MM.DD")
              }
              const fileName = `【入库订单】${start || ''}${end ? '-' + end : ''}.xlsx`;
              if ("download" in document.createElement("a")) {
                // 非IE下载
                const elink = document.createElement("a");
                elink.download = fileName;
                elink.style.display = "none";
                elink.href = URL.createObjectURL(blob);
                document.body.appendChild(elink);
                elink.click();
                URL.revokeObjectURL(elink.href); // 释放URL 对象
                document.body.removeChild(elink);
              } else {
                // IE10+下载
                navigator.msSaveBlob(blob, fileName);
              }
              this.commandBtnLoading = false
              this.commandShow = false
            }
          });
          reader.readAsText(blob, "utf-8");
        }).finally(() => {
            this.exportLoading = false;
          });
      } else {//导出入库excel 
        this.exportLoading = true
        const baseRequest = {
          merchantId: this.SeachParams.merchantId,
          companyId: this.SeachParams.companyId,
          orderNo: this.SeachParams.orderNo,
          // outOrderNo: this.SeachParams.outOrderNo,
          startTime: this.SeachParams.startTime,
          endTime: this.SeachParams.endTime,
          startTakeTime: this.SeachParams.startTakeTime,
          endTakeTime: this.SeachParams.endTakeTime,
          startAckTime: this.SeachParams.startAckTime,
          endAckTime: this.SeachParams.endAckTime,
          // endCreateTime: this.SeachParams.endCreateTime,
          // startCreateTime: this.SeachParams.startCreateTime,
          pageNum: this.page.pageNum,
          pageSize: this.page.pageSize,
          isIn: false
        };
        _api.outStockExcel({ isIn: false, ...baseRequest }).then((res) => {
          const content = res;
          const blob = new Blob([content]);

          let reader = new FileReader(); // 创建读取文件对象
          reader.addEventListener("loadend", () => {
            //
            try {
              let data = JSON.parse(reader.result);
              if (data.code === 0) {
                this.$message.error(data.msg || "导出失败");
                this.commandBtnLoading = false
              }
            } catch (err) {
              let start = "";
              let end = ""
              if (this.SeachParams.startTime && this.SeachParams.endTime) {
                start = moment(Number(this.SeachParams.startTime)).format("YYYY.MM.DD")
                end = moment(Number(this.SeachParams.endTime)).format("YYYY.MM.DD")
              }
              const fileName = `【出库订单】${start || ''}${end ? '-' + end : ''}.xlsx`;
              if ("download" in document.createElement("a")) {
                // 非IE下载
                const elink = document.createElement("a");
                elink.download = fileName;
                elink.style.display = "none";
                elink.href = URL.createObjectURL(blob);
                document.body.appendChild(elink);
                elink.click();
                URL.revokeObjectURL(elink.href); // 释放URL 对象
                document.body.removeChild(elink);
              } else {
                // IE10+下载
                navigator.msSaveBlob(blob, fileName);
              }
              this.commandBtnLoading = false
              this.commandShow = false
            }
          });
          reader.readAsText(blob, "utf-8");
        }).finally(() => {
            this.exportLoading = false;
          });
      }
    },
    // *****分割********
    // 切换
    BtnClick(type) {
      this.receiveType = type;
      // 给父组件传参
      this.$emit('accept', this.receiveType)
      if (this.receiveType == '01') {//入库
        this.handleCurrentChange(1)
      } else {
        this.handleCurrentChange2(1)
      }
    },
    // 订单详情
    goDetails(row) {
      let routeData = this.$router.resolve({
        path: "/RecallOrder/Details",
        query: { type: "edit", id: row.orderNo },
      });
      window.open(routeData.href, "_blank");
    },
    //入库表格切换页面
    handleCurrentChange(val) {
      if (val) {
        this.page.pageNum = val;
      }
      const baseRequest = {
        merchantId: this.SeachParams.merchantId,
        companyId: this.SeachParams.companyId,
        orderNo: this.SeachParams.orderNo,
        // outOrderNo: this.SeachParams.outOrderNo,
        // startTime: this.SeachParams.startTime,
        // endTime: this.SeachParams.endTime,
        startTakeTime: this.SeachParams.startTakeTime,
        endTakeTime: this.SeachParams.endTakeTime,
        startAckTime: this.SeachParams.startAckTime,
        endAckTime: this.SeachParams.endAckTime,
        endCreateTime: this.SeachParams.endCreateTime,
        startCreateTime: this.SeachParams.startCreateTime,
        pageNum: this.page.pageNum,
        pageSize: this.page.pageSize,
        isIn: true,//true:入库 false：出库
      };
      this.loading = true;
      _api.inStockList(baseRequest).then((res) => {
        if (res.code === 1) {
          this.seachDataList = res.data.records;
          this.page.total = res.data.total;
          this.page.pageNum = res.data.current;
          this.loading = false;
        }
        console.log(res);
        
      }).finally(() => {
        if (typeof this.SeachParams.disuseLoding === 'function') {
          this.SeachParams.disuseLoding();
        }
      });
    },
    // 出库表格切换页面
    handleCurrentChange2(val) {
      if (val) {
        this.page.pageNum = val;
      }
      const baseRequest = {
        merchantId: this.SeachParams.merchantId,
        companyId: this.SeachParams.companyId,
        orderNo: this.SeachParams.orderNo,
        // outOrderNo: this.SeachParams.outOrderNo,
        startTime: this.SeachParams.startTime,
        endTime: this.SeachParams.endTime,
        startTakeTime: this.SeachParams.startTakeTime,
        endTakeTime: this.SeachParams.endTakeTime,
        startAckTime: this.SeachParams.startAckTime,
        endAckTime: this.SeachParams.endAckTime,
        // endCreateTime: this.SeachParams.endCreateTime,
        // startCreateTime: this.SeachParams.startCreateTime,
        pageNum: this.page.pageNum,
        pageSize: this.page.pageSize,
        isIn: false
      };
      this.loading = true;
      _api.outStockList(baseRequest).then((res) => {
        if (res.code === 1) {
          this.seachDataList = res.data.records;
          this.page.total = res.data.total;
          this.page.pageNum = res.data.current;
          this.loading = false;
        }
        console.log(res);
        
      }).finally(() => {
        if (typeof this.SeachParams.disuseLoding === 'function') {
          this.SeachParams.disuseLoding();
        }
      });
    },
  },
};
</script>

<style scoped lang="scss" ref="stylesheet/scss">
 .import-business-dialog {
    .step {
      .step-title {
        color: #0981ff;
        margin-bottom: 10px;
        padding-left: 6px;
        border-left: 3px solid;
        border-radius: 1.5px;
      }

      .step-content,
      .step2-content {
        margin-bottom: 26px;
      }

      .step2-content>div {
        display: flex;
      }

      .step2-content {
        .upload-demo {
          display: flex;
          justify-content: start;

          .el-upload-list {
            width: 200px;
          }
        }
      }

      .tip {
        margin-bottom: 12px;

        .tip-title {
          color: #666666;
          margin-right: 12px;
        }

        .tip-content {
          color: #ff687b;
          font-size: 12px;
        }
      }
    }
  }
.top_swith {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

// 切换
.button_top {
  cursor: pointer;
  margin-bottom: 20px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.faultBtn {
  width: 280px;
  height: 32px;
  background: #f9fbfd;
  border: 1px solid #c1d1ff;
  box-shadow: 0px 4px 8px 0px rgba(196, 197, 198, 0.2);
  border-radius: 21px;
  display: flex;

  .left {
    text-align: center;
    color: white;
    width: 50%;
    height: 14px;
    font-size: 14px;
    font-family: FZLanTingHei-M-GBK;
    font-weight: 400;
    line-height: 30px;
    height: 30px;
    background: #0981ff;
    border-radius: 20px;
  }

  .right {
    text-align: center;
    color: #333;
    width: 50%;
    height: 14px;
    font-size: 14px;
    font-family: FZLanTingHei-M-GBK;
    font-weight: 400;
    line-height: 30px;
    height: 30px;
    border-radius: 20px;
  }
}

.GlobalTable {

  // 备注说明
  .Remarks {
    width: 100%;
    height: 100%;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 2; //控制行数
    overflow: hidden;
  }

  .operation-btn {
    /deep/.el-button {
      margin-left: 0;
    }
  }

  .verify-title {
    color: #0981ff;
    line-height: 16px;
    font-size: 16px;
    padding-left: 10px;
    border-left: 3px solid;
    margin-bottom: 10px;
  }

  .enable-tip {
    color: #ff8080;
    font-size: 14px;
    margin-bottom: 20px;

    .tip-sure {
      margin-top: 10px;
      color: #333333;
      font-size: 16px;
      text-align: center;
    }
  }

  .command {
    display: flex;
    align-items: center;
    margin-bottom: 10px;
  }

  .mb-10 {
    margin-bottom: 10px;
  }

  .mb-20 {
    margin-bottom: 20px;
  }

  .mr-50 {
    margin-right: 50px;
  }

  .ml-10 {
    margin-left: 10px;
  }

  .mr-10 {
    margin-right: 10px;
  }

  .font-w {
    font-weight: bold;
  }

  .flex {
    display: flex;
    align-items: center;
  }

  .flex-jb {
    display: flex;
    justify-content: space-between;
  }

  .flex-as {
    display: flex;
    align-items: flex-start;
  }
}

.one {
  flex: 1;
  text-align: center;
}

.one_img {
  width: 38px;
  height: 11px;
}

.fzs18 {
  font-size: 18px;
  font-family: PingFang SC;
  font-weight: bold;
}

.b25 {
  margin-bottom: 15px;
}

.fzs14 {
  font-size: 14px;
  font-family: FZLanTingHei-M-GBK;
  font-weight: 400;
  color: #666666;
}

.b03 {
  margin-bottom: 3px;
}

.two {
  max-height: 124px;
  background: #f5f6fa;
  border-radius: 6px;
  width: 360px;
  padding: 10px 13px 15px 13px;
  box-sizing: border-box;
}

.twoinfo {
  font-size: 16px;
  font-family: FZLanTingHei-DB-GBK;
  font-weight: 400;
  color: #0981ff;
  margin-bottom: 6px;
}

.twophone {
  font-size: 14px;
  font-family: FZLanTingHei-M-GBK;
  font-weight: 400;
  color: #333333;
  margin-bottom: 6px;
}

.loacinfo {
  font-size: 16px;
  font-family: FZLanTingHei-M-GBK;
  font-weight: 400;
  color: #333333;
  margin-top: 24px;
}

.three {
  min-height: 100px;
  background: #f9fbfd;
  border: 1px solid #c1d1ff;
  border-radius: 10px;
  margin-top: 12px;
  margin-bottom: 16px;
  max-height: 200px;
  overflow: auto;
  padding: 20px 20px;
  box-sizing: border-box;
  padding-bottom: 0;
}

.three::-webkit-scrollbar {
  width: 3px;
  // display: none;
}

/*定义滚动条轨道*/
.three::-webkit-scrollbar-track {
  border-radius: 10px;
  // display: none;
}

/*定义滑块*/
.three::-webkit-scrollbar-thumb {
  border-radius: 5px;
  background: rgba(27, 140, 236, 0.5);
  display: none;
}

.dainzhang {
  background: #fba516;
  border-radius: 4px;
  padding: 2px;
  line-height: 20px;
  font-size: 12px;
  color: #ffffff;
  margin-right: 6px;
}

.channeltype2 {
  height: 16px;
  background: #fba516;
  border-radius: 4px;
  padding: 2px;
  line-height: 20px;
  font-size: 12px;
  color: #ffffff;
  margin-right: 6px;
}

.cance-tip {
  color: #ff8080;
  font-size: 14px;
  margin-bottom: 20px;

  .tip-sure {
    margin-top: 10px;
    color: #333333;
    font-size: 16px;
    text-align: center;
  }
}

.command {
  display: flex;
  align-items: center;
  margin-bottom: 10px;
}
</style>
